import React from 'react';

import Layout from '../components/Layout';
import Band from '../components/Band/Band';
import { Container } from '../components/Grid/Grid';
import SEO from '../components/SEO';

const TermsPage = () => (
    <Layout>

        <SEO title="Terms and Conditions" description="Terms and conditions for One Planet Retail." />

        <Band size="lg">
            <Container>

                <h1>Terms and Conditions</h1>

            </Container>
        </Band>

    </Layout>
);

export default TermsPage;
